.my-notes-page {
    padding: 20px;
  }
  
  .notes-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .notes-table th, .notes-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .notes-table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  .notes-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .notes-table tr:hover {
    background-color: #ddd;
  }
  
  .add-note-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  