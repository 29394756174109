.landing-page {
  .landing-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  h2 {
    font-size: 50px;
    text-align: center;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
  }

  .section-description {
    font-size: 24px;
    text-align: center;
    color: var(--black500);
    text-align: center;
    max-width: 655px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 991px) {
  .landing-page {
    h2 {
      font-size: 28px;
    }

    .section-description {
      font-size: 16px;
    }
  }
}