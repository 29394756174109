.brew-dialog-component {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;

  .MuiDialog-paper {
    border-radius: 15px;
  }

  .brew-dialog-container {
    background-color: #F7F7F7;
    overflow: hidden;
    padding: 15px;

    .title {
      font-weight: 700;
    }

    .brew-dialog-content {
      background-color: #FFFFFF;
      border-radius: 10px;
      max-height: 630px;
      overflow-y: scroll;

      /* Hide scrollbar */
      &::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;
      /* Internet Explorer 10+ */
      scrollbar-width: none;
      /* Firefox */

      .dialog-content {
        padding: 10px 20px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .brew-dialog-component {
    max-width: 100% !important;
    width: 100%;

    .brew-dialog-container {
      .title {
        font-size: 14px;
        padding: 0;
      }
    }
  }
}