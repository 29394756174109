.brew-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.save-order-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
}

button:disabled {
  cursor: not-allowed;
}
