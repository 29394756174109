// .last-feature-component {
//   .feature-card {
//     display: flex;
//     flex-direction: row;
//     padding-top: 70px;
//     background-color: #f8f6f2;
//     border-radius: 10px;
//     padding: 35px 40px;
//     transition-timing-function: linear;
//     transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
//     transition-duration: .2s;

//     &:hover {
//       background-color: #f1e6fe;
//       transition-timing-function: linear;
//       transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
//       transition-duration: .2s;
//     }

//     .left-content {
//       flex: 0.8;
//       padding-right: 30px;
//       display: flex;
//       flex-direction: column;
//       justify-content: space-between;

//       .tag-title {
//         display: flex;
//         flex-direction: column;
//         justify-content: space-between;

//         .tag {
//           font-size: 20px;
//         }

//         h3 {
//           font-size: 32px;
//           line-height: 34px;
//           margin: 0;
//         }

//         p {
//           font-size: 18px;
//           color: #2c282fcc;
//         }
//       }

//     }

//     .right-content {
//       flex: 1.2;

//       .logo {
//         width: 100%;
//       }
//     }
//   }
// }

.last-feature-component {
  .feature-card {
    position: relative;
    display: flex;
    flex-direction: row;
    padding-top: 70px;
    background-color: #f8f6f2;
    border-radius: 10px;
    padding: 35px 40px;
    overflow: hidden;
    transition: transform 0.2s, background-color 0.2s;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      background-color: #2c282f;
      z-index: 0;
      transition: height 0.5s ease;
    }

    &:hover:before {
      height: 100%;
    }

    &:hover {
      .logo {
        opacity: 0;
      }
      .tag, .font-body, h3 {
        color: #fff;
      }
      .learn-more-button {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .left-content {
      flex: 0.8;
      padding-right: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      z-index: 1;

      .tag-title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .tag {
          font-size: 20px;
          transition: color 0.3s ease;
        }

        h3 {
          font-size: 32px;
          line-height: 34px;
          margin: 0;
          transition: color 0.3s ease;
        }

        p {
          font-size: 18px;
          color: #2c282fcc;
          transition: color 0.3s ease;
        }
      }
    }

    .right-content {
      flex: 1.2;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;

      .logo {
        width: 100%;
        transition: opacity 0.3s ease;
      }
    }

    .learn-more-button {
      display: flex;
      align-items: center;
      opacity: 0;
      position: absolute;
      top: 35px;
      right: 40px;
      transform: translateY(20px);
      transition: opacity 0.3s, transform 0.3s;
      z-index: 2;
      font-size: 17px;
      color: var(--white);
      gap: 10px;

      .arrow-content {
        background-color: #fff86d;
        padding: 12px 10px;
        border-radius: 5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        .arrow-right {
          display: inline-block;
          width: 20px; /* Adjust as needed */
          height: 2px; /* Thin arrow shaft */
          background-color: black;
          position: relative;
        }

        .arrow-right::after {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 7px; /* Size of the arrowhead */
          height: 7px; /* Size of the arrowhead */
          border-top: 2px solid black; /* Thin arrowhead */
          border-right: 2px solid black; /* Thin arrowhead */
          transform: translateY(-50%) rotate(45deg);
        }
      }
    }
  }
}
