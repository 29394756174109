.features-section {
  .features-content {
    padding: 20px 0 30px;
    .features-content-1 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0 40px;
    }

    .last-feature {
      padding-top: 40px;
    }
  }
}

@media screen and (max-width: 991px) {
  .features-section {
    .features-content {
      .features-content-1 {
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
      }
      
      .last-feature {
        padding-top: 20px;
      }
    }

  }
}