.brw-work-item-component {
  .work-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--gray100);
    border-radius: 8px;
    min-height: 576px;
    box-shadow: none;
    transition-timing-function: linear;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-duration: .2s;
    padding: 40px 32px;

    &:hover {
      transition-timing-function: linear;
      transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
      transition-duration: .2s;
    }

    .title-content {
      min-height: 114px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      h3 {
        font-size: 2rem;
        margin: 0;
      }
    }

    p {
      font-size: 16px;
      color: var(--black);
    }

    .learn-more-button {
      padding: 16px 24px;
      background-color: #1c1a1f;
      border-radius: 8px;
      max-width: fit-content;
      font-size: 17px;
      color: var(--white);
    }
  }

  &:nth-child(1) {
    .work-card:hover {
      background-color: #eaf1fb;
    }
  }

  &:nth-child(2) {
    .work-card:hover {
      background-color: #f1e6fe;
    }
  }

  &:nth-child(3) {
    .work-card:hover {
      background-color:#e3f7ea;
    }
  }

  &:nth-child(4) {
    .work-card:hover {
      background-color: #ffe9e5;
    }
  }
}
