:root {
  --main-color: #1D181F;

  --black: #000;
  --black500: #2c282fcc;
  --black800: #2d3748;

  --white: #FFF;

  --gray100: #f7f7f8;
  --gray200: #f8f6f2;
  --gray500: #ddd;
  --grey650_80: rgba(96, 96, 96, 0.8);

  --red800: #EB5757;
  
  --blue300_30: rgba(10, 26, 50, 0.3);

}