// flex
.flex-between-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.v-r {
  display: flex;
  flex-direction: column;
}

.v-c {
  display: flex;
  align-items: center;
}

.h-c {
  display: flex;
  justify-content: center;
}

.center {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

//button
.button {
  cursor: pointer !important;
}

//box shadow
.shadow-object {
  box-shadow: 0px 14px 30px rgba(3, 45, 30, 0.21);
}
