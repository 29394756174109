/* ViewBrews.css */

.view-brews-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }
  
  .view-brews-page h1 {
    margin-bottom: 20px;
  }
  