//Inter
@font-face {
  font-family: "RedHatDisplay";
  src: local("RedHatDisplay-Bold"), url("../../fonts/Inter/static/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "RedHatDisplay";
  src: local("RedHatDisplay-SemiBold"), url("../../fonts/Inter/static/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "RedHatDisplay";
  src: local("RedHatDisplay-Medium"), url("../../fonts/Inter/static/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "RedHatDisplay";
  src: local("RedHatDisplay-Regular"), url("../../fonts/Inter/static/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
}

.font-bold {
  font-family: agrandirbold, system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

.font-body {
  font-family: Inter, system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}