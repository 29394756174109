.brew-works-section {
  .brew-works-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0 20px;
    padding-top: 70px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .brew-works-section {
    .brew-works-content {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
  }
}

@media screen and (max-width: 991px) {
  .brew-works-section {
    .brew-works-content {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
      padding-top: 30px;
    }
  }
}