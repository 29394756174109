.feature-component {
  .feature-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f8f6f2;
    border-radius: 10px;
    padding: 35px 40px;
    min-height: 385px;
    overflow: hidden;
    transition: transform 0.2s;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      background-color: #2c282f;
      z-index: 0;
      transition: height 0.5s ease;
    }

    &:hover:before {
      height: 100%;
    }

    &:hover {
      .logo {
        display: none;
      }

      .learn-more-button {
        opacity: 1;
        transform: translateY(0);
      }

      .tag {
        color: #fff86d;
        /* Change to your desired color */
      }

      h3,
      p {
        color: var(--white);
        /* Change to your desired color */
      }
    }

    .header-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      min-height: 170px;
      position: relative;
      z-index: 1;

      .tag-title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .tag {
          font-size: 20px;
          transition: color 0.3s ease;
          /* Transition for smooth color change */
        }

        h3 {
          font-size: 32px;
          line-height: 34px;
          margin: 0;
        }
      }

      .logo {
        width: 120px;
        object-fit: contain;
        transition: opacity 0.3s ease;
      }
    }

    p {
      font-size: 18px;
      color: #2c282fcc;
      position: relative;
      z-index: 1;
      transition: color 0.3s ease;
      /* Transition for smooth color change */
    }

    .learn-more-button {
      display: flex;
      position: absolute;
      top: 0px;
      right: 0px;
      opacity: 0;
      transition: opacity 0.3s, transform 0.3s;
      max-width: fit-content;
      font-size: 17px;
      color: white;
      z-index: 1;
      gap: 10px;

      .arrow-content {
        background-color: #fff86d;
        padding: 12px 10px;
        border-radius: 5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        .arrow-right {
          display: inline-block;
          width: 20px;
          /* Adjust as needed */
          height: 2px;
          /* Adjust as needed */
          background-color: black;
          position: relative;
        }

        .arrow-right::after {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 7px;
          /* Adjust as needed */
          height: 7px;
          /* Adjust as needed */
          border-top: 2px solid black;
          border-right: 2px solid black;
          transform: translateY(-50%) rotate(45deg);
        }
      }

    }
  }
}