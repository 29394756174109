.drop-down-select-component {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;

  .label {
    font-size: 12px;
  }

  .drop-down-select-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 35px;
    width: 100%;
    border: solid 1px var(--gray500);
    padding: 0 15px;
    border-radius: 5px;
    position: relative;

    .select-header {
      justify-content: space-between;
    }

    .placeholder-des {
      font-size: 14px;
      color: var(--blue900);
      opacity: 0.3;
    }

    .value {
      font-size: 14px;
    }

    .drop-list-container {
      position: absolute;
      top: 42px;
      left: 0px;
      right: 0;
      background-color: var(--white);
      z-index: 9;

      .drop-list-item {
        padding: 5px 15px;
        cursor: pointer;

        &:hover {
          background-color: var(--gray500);
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .drop-down-select-component {
    .value {
      font-size: 14px;
    }

    .drop-down-select-content {
      height: 30px;
    }
  }
}