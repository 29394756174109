.brand-section {
  background-color: #2c282f;
  display: flex;
  flex-direction: row;
  padding: 40px 15px;

  .brand-description {
    min-width: 310px;

    h3 {
      font-size: 24px;
      font-weight: 600;
      color: var(--white);
      margin: 0;

      span {
        color: #fff86d;
      }
    }

    p {
      font-size: 16px;
      color: #ffffffcc;
    }
  }

  .brand-list {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 20px;

    .brand-logos {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 70px;
    }
  }
}

@media screen and (max-width: 991px) {
  .brand-section {
    flex-direction: column;

    .brand-description {}
  }
}