/* General Styles */
body {
  font-family: 'Inter', sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
  background-color: #f9fafb;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1200px;
}

/* Typography */
.t-120 { font-size: 2.9375rem; line-height: .9; }
.t-80 { font-size: 5rem; line-height: 1; }
.t-72 { font-size: 2rem; line-height: 1; }
.t-64 { font-size: 2rem; line-height: 1.1; }
.t-54 { font-size: 1.875rem; line-height: 1.1; }
.t-50 { font-size: 1.75rem; line-height: 1.15; }
.t-48 { font-size: 1.75rem; line-height: 1.05; }
.t-42 { font-size: 1.75rem; line-height: 1.2; letter-spacing: .01em; }
.t-40 { font-size: 1.75rem; line-height: 1.1; }
.t-38 { font-size: 1.625rem; line-height: 1.2; letter-spacing: .01em; }
.t-36 { font-size: 1.5rem; line-height: 1.2; letter-spacing: .01em; }
.t-34 { font-size: 1.5rem; line-height: 1.3; letter-spacing: .01em; }
.t-32 { font-size: 1.5rem; line-height: 1.1; letter-spacing: .01em; }
.t-30 { font-size: 1.5rem; line-height: 1.1; }
.t-28 { font-size: 1.4375rem; line-height: 1.1; letter-spacing: .01em; }
.t-26 { font-size: 1.25rem; line-height: 1.3; letter-spacing: .01em; }
.t-24 { font-size: 1.125rem; line-height: 1.5; }
.t-22 { font-size: 1.125rem; line-height: 1.55; }
.t-20 { font-size: 1.125rem; line-height: 1; }
.t-18 { font-size: 1rem; line-height: 1.5; }
.t-16 { font-size: 1rem; line-height: 1.5; }
.t-15 { font-size: .9375rem; line-height: 1.5; }
.t-14 { font-size: .875rem; line-height: 1.45; }
.t-14-legal { font-size: .6875rem; line-height: 1.5; }

/* FAQ Section */
.faq-section {
  padding: 60px 20px;
  background-color: #ffffff;
}
.faq-section .MuiTypography-h4 {
  color: #1a202c;
  text-align: center;
  margin-bottom: 30px;
}
.faq-section .MuiAccordion-root {
  margin-bottom: 15px;
}
.faq-section .MuiTypography-h6 {
  font-weight: bold;
}

/* Features Section */
.features-section {
  padding: 80px 20px;
  background-color: #f9fafb;
}
.features-section .MuiTypography-h4 {
  color: #1a202c;
  text-align: center;
  margin-bottom: 40px;
}

/* Footer Section */
.footer-section {
  padding: 40px 20px;
  background-color: var(--black800);
  color: #ffffff;
}
.footer-section .MuiTypography-h6 {
  color: #ffffff;
  margin-bottom: 10px;
}
.footer-section .MuiTypography-body1 {
  color: #a0aec0;
}
.footer-link {
  color: #63b3ed;
  text-decoration: none;
}
.footer-link:hover {
  text-decoration: underline;
}
.footer-copy {
  margin-top: 20px;
  text-align: center;
}

/* Hero Section */
.hero-section {
  padding: 120px 20px;
  background-color: #1a202c;
  color: #ffffff;
  text-align: center;
}
.hero-title {
  font-weight: 700;
  margin-bottom: 20px;
}
.hero-subtitle {
  margin-bottom: 40px;
}
.hero-button {
  margin-top: 20px;
  background-color: #ed8936;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 8px;
}
.hero-button:hover {
  background-color: #dd6b20;
}

/* Highlights Section */
.highlights-section {
  padding: 60px 20px;
  background-color: #ffffff;
}
.highlight-title {
  color: var(--black800);
  margin-bottom: 15px;
}
.highlight-description {
  color: #4a5568;
}

/* Logo Collection Section */
.logo-collection-section {
  padding: 60px 20px;
  background-color: #f9fafb;
  text-align: center;
}
.client-logo {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

/* Pricing Section */
.pricing-section {
  padding: 80px 20px;
  background-color: #ffffff;
}
.pricing-section .MuiTypography-h4 {
  color: #1a202c;
  text-align: center;
  margin-bottom: 40px;
}
.pricing-card {
  border: 1px solid #e2e8f0;
  transition: box-shadow 0.3s;
  background-color: #ffffff;
  border-radius: 8px;
}
.pricing-card:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}
.pricing-title {
  color: var(--black800);
  margin-bottom: 15px;
}
.pricing-price {
  color: #4a5568;
  margin-bottom: 30px;
}
.pricing-features {
  list-style: none;
  padding: 0;
  margin: 0 0 30px 0;
}
.pricing-feature {
  color: #4a5568;
  margin-bottom: 10px;
}
.pricing-button {
  width: 100%;
  background-color: #ed8936;
  color: #ffffff;
  padding: 10px 0;
  border-radius: 8px;
}
.pricing-button:hover {
  background-color: #dd6b20;
}

/* Testimonials Section */
.testimonials-section {
  padding: 80px 20px;
  background-color: #f9fafb;
}
.testimonial-card {
  border: 1px solid #e2e8f0;
  transition: box-shadow 0.3s;
  background-color: #ffffff;
  border-radius: 8px;
}
.testimonial-card:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}
.testimonial-avatar {
  margin-bottom: 15px;
  width: 80px;
  height: 80px;
}
.testimonial-name {
  color: var(--black800);
  margin-bottom: 10px;
}
.testimonial-feedback {
  color: #4a5568;
}
