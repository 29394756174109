.add-brew-btn-component {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: auto;
  min-height: 429px;
  padding: 10px;
  border: 2px dashed #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  
  .plus-icon {
    font-size: 24px;
    color: #ddd;
  }
}
