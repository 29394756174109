.add-brew-form-component {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  width: 100%;
  overflow-y: scroll;

  /* Hide scrollbar */
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */

  .inline {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .image-preview {
    position: relative;
    margin-top: 10px;
    text-align: center;

    .image-preview-img {
      max-width: 100%;
      height: auto;
      border: 1px solid #ddd;
      border-radius: 4px;
    }

    .image-preview-remove {
      position: absolute;
      top: 5px;
      right: 5px;
      background: rgba(255, 255, 255, 0.7);
      border-radius: 50%;
    }
  }

  .submit-btn,
  .upload-btn {
    margin: 0;
    background-color: #1D181F;
    border-radius: 30px;

    &:hover {
      background-color: #1D181F;
    }
  }
}

@media screen and (max-width: 991px) {
  .add-brew-form-component {
    .inline {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}