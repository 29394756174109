/* style.scss */
.no-scroll {
  overflow: hidden;
}

.ReactModal__Overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--grey650_80) !important;
  backdrop-filter: blur(7px);
  z-index: 102;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .main-modal-component {
    position: relative;
    border: none;
    border-radius: 12px;
    padding: 0px;
    background-color: white;
    box-shadow: 0px 4px 36px var(--blue300_30);
    z-index: 103;
    height: 95%;
    max-height: 493px;
    width: 72%;
    max-width: 700px;
    // overflow-y: scroll; /* Disable scroll within the modal */
    overflow: hidden;

    &:focus-visible {
      outline: none;
    }
  }
}

@media screen and (min-width: 767px) and (max-width: 992px) {
  .ReactModal__Overlay {
    .main-modal-component {
      height: 650px;
    }
  }
}

@media screen and (max-width: 768px) {
  .ReactModal__Overlay {
    .main-modal-component {
      width: 90%;
      height: 90%;
    }
  }
}