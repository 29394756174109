.brew-item-component {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  width: 300px;
  height: 400px; // Ensuring all cards have the same height
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;

  h2 {
    font-weight: 700;
    margin-bottom: 10px;
  }

  .brew-img-container {
    width: 100%;
    height: calc(100% - 50px);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .brew-image {
      width: 100%;
      height: 100%;
      object-fit: contain; // Ensure the image fits without being cut off
      transition: opacity 0.5s;
    }

    .brew-details {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.9);
      padding: 10px;
      box-sizing: border-box;
      opacity: 0;
      transition: opacity 0.5s;
      display: flex;
      flex-direction: column;
      justify-content: space-between; // Make space for delete button
      align-items: flex-start;
      text-align: left;

      p {
        margin: 0;
      }

      .delete-button-container {
        align-self: flex-end;
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }
    }
  }

  &:hover .brew-image {
    opacity: 0;
  }

  &:hover .brew-details {
    opacity: 1;
  }
}
