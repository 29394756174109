.view-notes-page {
    padding: 20px;
  }
  
  .notes-list {
    margin-bottom: 20px;
  }
  
  .note-item {
    margin-bottom: 10px;
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .note-item textarea,
  .note-item p {
    flex-grow: 1;
    height: 80px;
    padding: 10px;
    border: 1px solid #ddd;
    margin: 0;
    resize: none;
    box-sizing: border-box;
  }
  
  .note-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
    border-left: 1px solid #ddd;
    height: 100%;
  }
  
  .note-actions button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
  }
  
  .note-date {
    text-align: right;
    font-size: 12px;
    color: #666;
  }
  
  .add-note-section {
    margin-bottom: 20px;
  }
  
  .add-note-section textarea {
    width: 100%;
    height: 80px;
    padding: 10px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    box-sizing: border-box;
    resize: none;
  }
  
  .add-note-section button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .save-notes-button {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    cursor: pointer;
  }
  