.my-brews-page {
  max-width: 1200px; /* Adjust this to match the max-width of your navbar/container */
  margin: 0 auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content */
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loading-text {
  margin-top: 16px;
}
