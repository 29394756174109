.brew-modal-component {
  color: var(--main-color);
  position: relative;
  height: 100%;
  width: 100%;

  .close-btn {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
    fill: var(--main-color);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-close {
      width: 100%;
      height: 100%;

      div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(45deg);
      }
    }
  }

  .brew-main-content {
    padding: 15px;
    background-color: #F7F7F7;
    height: 100%;

    .modal-header {
      h2 {
        font-size: 20px;
        margin: 10px 0 !important;
      }
    }

    .modal-body {
      background-color: var(--white);
      border-radius: 10px;
      padding: 15px;
      height: calc(100% - 52px);
      width: 100%;
    }
  }
}

@media screen and (max-width: 991px) {
  .brew-modal-component {
    .brew-main-content {

      .modal-header,
      .modal-body,
      .modal-footer {
        padding: 10px;
      }
    }

    .brew-main-content {

      .modal-header {
        h2 {
          font-size: 15px;
          margin: 0 !important;
        }
      }
    }
  }
}