.form-input-component {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  color: var(--main-color);

  .input-label {
    font-size: 12px;
  }

  .input-container {
    height: 35px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--gray500);
    overflow: hidden;
    border-radius: 5px;
    padding: 0 15px;

    &.textarea {
      height: 70px;
      padding: 5px 15px;
    }

    &.focus {
      border: 2px solid var(--main-color);
    }

    ::placeholder {
      color: var(--main-color);
    }

    .main-input {
      flex: 1;
      height: 100%;
      background: none;
      font-size: 14px;
      color: var(--main-color);
      border: none;

      &:focus,
      &:focus-visible,
      &:active {
        border: none;
        outline: none;
      }

      &.large-spacing {
        letter-spacing: 0.3em;
      }
    }

    .char-counter {
      font-size: 12px;
      font-style: italic;
      color: var(--main-color);
      position: absolute;
      bottom: 5px;
      right: 10px;
    }

    span {
      font-size: 12px;
      font-style: italic;
    }
  }

  .error {
    font-size: 12px;
    color: var(--red800);
  }
}

@media screen and (max-width: 991px) {
  .form-input-component {
    gap: 1px;

    .input-container {
      height: 30px;
      padding: 0 10px;
    }
  }
}
